/* eslint-disable vue/no-deprecated-destroyed-lifecycle */
<template>
  <div class="form">
    <v-card class="mx-auto" max-width="1000">
      <v-img class="white--text align-end" src="/img/banner.jpg">
        <v-card-title>{{ $t('requestForm.banner.requestAccess') }}</v-card-title>
      </v-img>

      <v-card-text class="text--primary">
        <v-row>
          <v-col cols="12">
            <v-form ref="form" v-model="valid" :lazy-validation="false">
              <training-section v-model="form.trainingInformation" />
              <PersonalData v-model="form.personalData" />
              <education-section v-model="form.education" />
              <AssetsSection v-model="form.assets" />
              <AccessCards v-if="anyAssetsRequiresCard" />
              <template v-if="!allAssetsRequiresCard">
                <v-row class="px-5">
                  <v-col cols="8">
                    <span
                      class="subtitle-2"
                    >{{ $t('requestForm.accessTags.hasOwnTags.sectionHeader') }}</span>
                    <br />
                    <i class="caption">{{ $t('requestForm.accessTags.hasOwnTags.instruction') }}</i>
                    <v-radio-group v-model="hasOwnTags" :rules="radioRules">
                      <v-radio :label="$t('requestForm.accessTags.hasOwnTags.yes')" :value="true"></v-radio>
                      <v-radio :label="$t('requestForm.accessTags.hasOwnTags.no')" :value="false"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col md="4" cols="6">
                    <span class="subtitle-2">{{ $t('requestForm.pin.header') }}</span>
                    <br />
                    <i class="caption">{{ $t('requestForm.pin.instruction') }}</i>
                    <v-text-field
                      v-model="form.accessTags.pin"
                      class="mt-5"
                      filled
                      required
                      :rules="tagPinRules"
                      :label="$t('requestForm.accessTags.pinCode')"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <AccessTags v-if="hasOwnTags" v-model="form.accessTags" />
                <DeliveryAddress v-else v-model="form.deliveryAddress" />
              </template>
              <ReasonDescription v-model="form.accessReason" />
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pa-6">
        <v-btn
          color="primary"
          :disabled="sendButtonDisabled"
          class="px-5"
          rounded
          @click="handleSend"
        >{{ $t('actions.send') }}</v-btn>
        <v-btn color="primary" rounded outlined @click="handlePrint">{{ $t('actions.print') }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-container class="white lighten-5 pt-10"></v-container>
    <v-dialog v-model="waitDialog" persistent width="500">
      <v-card>
        <v-card-title class="text-h5">{{ $t('requestForm.waitDialog.header') }}</v-card-title>
        <v-card-text class>
          {{ $t('requestForm.waitDialog.message') }}
          <br />
          {{ $t('requestForm.waitDialog.estimate', {sites: form.assets.length, time: form.assets.length * 2}) }}
          <v-progress-linear indeterminate color="primary" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { mapActions, mapGetters } from 'vuex'
import validators from '../validators'

export default {
  name: 'RequestForm',
  data: () => ({
    dayjs,
    ...validators,
    valid: false,
    snackbar: false,
    sendButtonDisabled: false,
    waitDialog: false,
    hasOwnTags: true,
    form: {
      type: 'individual',
      trainingInformation: {
        trainingDate: null,
        isTrainingCompleted: undefined
      },
      education: [],
      personalData: {
        isSwedishNational: undefined,
        socialSecurityNumber: null,
        passportNumber: null,
        birthDate: null,
        phoneNumber: null,
        firstName: null,
        lastName: null,
        email: null,
        company: null,
        profession: null,
        userId: null
      },
      accessReason: {
        reason: null
      },
      accessTags: {
        pin: null,
        so: null,
        rc: null,
        mi: null
      },
      deliveryAddress: {
        recipient: '',
        attestant: '',
        street: '',
        zipcode: '',
        city: '',
        country: ''
      },
      assets: []
    }
  }),
  computed: {
    ...mapGetters(['isValidated']),
    selectedAssets () {
      return this.form.assets
    },
    anyAssetsRequiresCard () {
      return this.form.assets.some(item => 'requiresCard' in item && item.requiresCard !== false)
    },
    allAssetsRequiresCard () {
      return this.form.assets.every(item => 'requiresCard' in item && item.requiresCard !== false)
    }
  },
  watch: {
    isValidated (v) {
      v || this.$router.push('/')
    }
  },
  methods: {
    ...mapActions(['sendRequest', 'createMessage']),
    async handleSend () {
      if (this.$refs.form.validate()) {
        try {
          this.sendButtonDisabled = true
          this.waitDialog = true
          await this.sendRequest(this.form)
        } finally {
          this.sendButtonDisabled = false
          this.waitDialog = false
        }
      } else {
        this.createMessage({ text: this.$t('messages.formValidationWarning'), color: 'warning' })
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    resetValidation () {
      this.$refs.form.resetValidation()
    },
    handlePrint () {
      window.print()
    }
  }
}
</script>
<style>
</style>
